import React, { useEffect, useState, useMemo } from 'react'
import Amplify from '@aws-amplify/core'
import { Typography } from '@material-ui/core'
import { LocationProps } from '@reach/router'
import { graphql, navigate } from 'gatsby'
import { TFunction } from 'i18next'
import { withTranslation } from 'react-i18next'
import {
  generateTrackingLink,
  getBrands,
  getBrandsLogosURLSrc,
} from '../../utils/brandsHelper'
import { trendingBrandsLogos } from '../../utils/trendingBrandsLogos'
import { sendExtensionMessage } from '../../utils/extensionHelper'
import { isUserAuthenticated } from '../../utils/isUserAuthenticated'
import { useAppState } from '../appState'
import earnCashBack from '../assets/images/earnCashBack.png'
import earnCashBackMobile from '../assets/images/earnCashBack-mobile.png'
import earnCashBack2 from '../assets/images/earnCashBack2.jpg'
import extensionInstalledImg from '../assets/images/extensionInstalled.png'
import BrandItem from '../components/brandItem'
import BrandCardsList from '../components/brandsList'
import ChirpyestBlog from '../components/chirpyestBlog'
import ExtensionWarningModal from '../components/extensionWarningModal'
import MiddleSection from '../components/homeSections/homeMiddleSection'
import TopSection from '../components/homeSections/topSection'
import { getBlogs } from '../components/member/blog-posts'
import { FONTS, HASH_SIGN_IN, ROUTES } from '../constants'
import useNavigateToExtStore from '../hooks/useNavigateToExtStore'
import Layout from '../layouts/defaultLayout'
import { styles } from '../pagesStyles/homeStyles'
import { userRemovedExtension } from './../../utils/userRemovedExtension'
import awsConfig from './../aws-exports'
import CHRArrowButton from './../components/arrowButton'
import BlockUserInterface from './../components/blockUserInterface'
import ContentContainer from './../components/contentContainer'
import CHROutlinedButton from './../components/outlinedButton'
import TrendingProducts from '../components/trendingProducts'
import SEO from '../components/seo'
import BrowserExtension from '../pages/best-cash-back-browser-extension-website'

Amplify.configure(awsConfig)

const deviceType = () => {
  const ua = navigator.userAgent
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
    return 'tablet'
  } else if (
    /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
      ua
    )
  ) {
    return 'mobile'
  }
  return 'desktop'
}

interface HomePageProps {
  data: {
    site: {
      siteMetadata: {
        title: string
      }
    }
    file: {
      childImageSharp: {
        fluid: any
      }
    }
  }
  t: TFunction
  location: LocationProps
}

const HomePage = ({ data, t, location, ...otherProps }: HomePageProps) => {
  const [appState] = useAppState()
  const classes = styles()
  const [
    isExtWarningModalOpen,
    setExtWarningModalOpen,
    navigateToStore,
  ] = useNavigateToExtStore()

  const [landingBrands, setLandingBrands] = useState([])
  const [isPageLoading, setIsPageLoading] = useState(true)
  const [param] = window.location.search.slice(1).split('&')
  const [blogs, setBlogs] = useState([])
  const [extensionInstalled, setExtensionInstalled] = useState(true)
  let extensionTimeoutRef = useMemo(
    () =>
      setTimeout(() => {
        setExtensionInstalled(false)
      }, 1 * 1000),
    []
  )

  useEffect(() => {
    userRemovedExtension(param)
    isUserAuthenticated().then(result => {
      if (!!result) {
        const urlParams = new URLSearchParams(window.location.search)
        const signInFromExtension = urlParams.get('signInFromExtension')
        navigate(
          `${ROUTES.member}${
            signInFromExtension
              ? '?signInFromExtension=signInFromExtension'
              : ''
          }`
        )
      } else {
        setIsPageLoading(false)
      }
    })
    // getBrands()
    //   .then(response => {
    //     let filteredBrands = response.data.data.filter(item => item.isTrending)
    //     const filteredTrendingBrands = getBrandsLogosURLSrc(
    //       filteredBrands,
    //       trendingBrandsLogos
    //     )
    //     const limitedBrands = filteredTrendingBrands.slice(0, 16)
    //     setLandingBrands(limitedBrands)
    //   })
    //   .catch(() => setLandingBrands([]))
    // getBlogsHelper()
    window.addEventListener('chirpExtMessage', onSendExtMessage)
    sendExtensionMessage('ping')
    return window.removeEventListener('chirpExtMessage', onSendExtMessage)
  }, [])

  const onSendExtMessage = data => {
    if (data.detail === 'pong') {
      setExtensionInstalled(true)
      window.localStorage.setItem('extensionDownloaded', 'true')
      clearTimeout(extensionTimeoutRef)
    } else {
      window.localStorage.setItem('extensionDownloaded', 'false')
    }
  }

  // const getBlogsHelper = async () => {
  //   const blogs = await getBlogs()
  //   setBlogs(blogs)
  // }

  if (isPageLoading) {
    return <BlockUserInterface />
  } else {
    return (
      <BrowserExtension signInModal={location.hash.includes(HASH_SIGN_IN)} />

      // <Layout openSignInModal={location.hash.includes(HASH_SIGN_IN)}>
      //   <SEO
      //     title={t('seo.landingPage.title')}
      //     description={t('seo.landingPage.description')}
      //   />
      //   {/*---------------------- section 1 - MAIN CONTENT ----------------------*/}
      //   <TopSection
      //     isExtentionDownloaded={extensionInstalled}
      //     addExtention={navigateToStore}
      //   />
      //   {/*---------------- section 3 - TRENDING ----------------*/}
      //   <TrendingProducts />
      //   <div className={classes.flexOrder}>
      //     {/*---------------------- section 2 - HOW YOU EARN CASH BACK ----------------------*/}
      //     <MiddleSection
      //       handleArrowClick={() => navigate(ROUTES.join)}
      //       image={
      //         deviceType() === 'mobile' ? earnCashBackMobile : earnCashBack
      //       }
      //       rightChildren={
      //         <div>
      //           <Typography variant="h2" className={classes.middleSectionTitle}>
      //             {t('home.howYouEarnTitle')}
      //           </Typography>
      //           <Typography
      //             variant="subtitle1"
      //             component="p"
      //             className={classes.middleSectionContent}
      //           >
      //             {t('home.howYouEarnText')}
      //           </Typography>
      //         </div>
      //       }
      //       buttonLabel="join chirpyest"
      //     />
      //     {/*---------------------- section 4 - BRANDS LIST ----------------------*/}
      //     <div className={classes.brandsList}>
      //       <div className={classes.mobileBackground}>
      //         <section className={classes.brandsListSection}>
      //           <div className={classes.brandsListTitle}>
      //             <Typography variant="h2">
      //               {t('home.thousandsOfBrands')}
      //             </Typography>
      //             <Typography variant="subtitle1">
      //               {t('home.homeFashionBeauty')}
      //             </Typography>
      //             <div className={classes.addChirpyestBtn}>
      //               <CHRArrowButton
      //                 label={t('home.seeAllBrands')}
      //                 onClick={() => navigate(ROUTES.brands)}
      //                 hoverEffects={true}
      //               />
      //             </div>
      //           </div>
      //           <div className={classes.brandsListContainer}>
      //             <BrandCardsList>
      //               {landingBrands.map((brand: any, index: number) => {
      //                 return (
      //                   <BrandItem
      //                     brandLogo={brand.src}
      //                     name={brand.brandName}
      //                     cashBack={brand.commission}
      //                     link={generateTrackingLink(
      //                       brand,
      //                       {
      //                         userId: appState.userId,
      //                         userName: appState.userProfile?.userName,
      //                       },
      //                       '',
      //                       '',
      //                       Boolean(appState.userId)
      //                     )}
      //                     key={index}
      //                     isCardOnLanding
      //                   />
      //                 )
      //               })}
      //             </BrandCardsList>
      //           </div>
      //         </section>
      //       </div>
      //     </div>
      //     {/*---- section 5 - ADD OUR BROWSER EXTENSION ----*/}
      //     {!extensionInstalled ? (
      //       <MiddleSection
      //         handleArrowClick={navigateToStore}
      //         image={earnCashBack2}
      //         rightChildren={
      //           <div>
      //             <Typography variant="h3">{t('home.earnCash')}</Typography>
      //             <Typography variant="h2">
      //               {t('home.addOurExtension')}
      //             </Typography>
      //             <Typography
      //               variant="subtitle1"
      //               component="p"
      //               className={classes.middleSectionContent}
      //             >
      //               {t('home.earnCashText')}
      //             </Typography>
      //           </div>
      //         }
      //       />
      //     ) : (
      //       <MiddleSection
      //         handleArrowClick={navigateToStore}
      //         image={extensionInstalledImg}
      //         rightChildren={
      //           <div>
      //             <Typography variant="h3">{t('home.earnCash')}</Typography>
      //             <Typography variant="h2">
      //               {t('memberHome.useOurExtension')}
      //             </Typography>
      //             <Typography
      //               variant="subtitle1"
      //               component="p"
      //               className={classes.middleSectionContent}
      //             >
      //               {t('memberHome.extensionInstalledText')}
      //             </Typography>
      //           </div>
      //         }
      //       />
      //     )}
      //   </div>
      //   {/*---------------- section 6 - BLOG ----------------*/}
      //   <ChirpyestBlog blogs={blogs} />
      //   {/*------------ section 7 - What are you waiting for ------------*/}
      //   <div className={classes.sectionContainer}>
      //     <ContentContainer>
      //       <section className={classes.centered}>
      //         <Typography variant="h1" component="h2">
      //           {t('home.whatAreYouWaiting')}
      //         </Typography>
      //         <CHROutlinedButton
      //           label={t('shared.joinChirpyest')}
      //           font={FONTS.Graphik.GraphikMedium}
      //           onClick={() => navigate(ROUTES.join)}
      //         />
      //       </section>
      //     </ContentContainer>
      //   </div>
      //   <ExtensionWarningModal
      //     isOpen={isExtWarningModalOpen}
      //     onClose={() => setExtWarningModalOpen(false)}
      //   />
      // </Layout>
    )
  }
}

export default withTranslation()(HomePage)

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    file(relativePath: { eq: "chirpyest-mobile.png" }) {
      childImageSharp {
        fluid(maxWidth: 1024) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
